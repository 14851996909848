import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Element from './ElementComponent.react'

export default class PeriodicTableComponent extends Component {
    state = {
      selectedElemSym: [],
      mode: "single"
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.mode !== this.props.mode) {

        if(prevProps['mode'] == 'multi') {
          this.resetSelection()
        }
      }
    }

    resetSelection() {
      var newValue = this.props.value
      newValue[this.props.focusedMaterialSystem]['selected_elems'] = []

      this.setState({ selectedElemSym: [] });
      this.props.setProps({ value: newValue });
      this.props.setProps({ lastUpdated: Date.now() });
    }

    deselectElem(elemSym) {
      var index = this.state.selectedElemSym.indexOf(elemSym);

      if (index !== -1) {
        this.state.selectedElemSym = this.state.selectedElemSym.splice(index, 1);
        return this.state.selectedElemSym;
      }
    }


    multiSelectElem(elemSym) {
      var newValue = this.props.value
      var selectedElems = []

      if(this.state.selectedElemSym.includes(elemSym)){
        /* toggle selection */
        selectedElems = this.props.value[this.props.focusedMaterialSystem]['selected_elems'].filter((elem) => elem != elemSym)
        newValue[this.props.focusedMaterialSystem]['selected_elems'] = selectedElems

        this.setState({ selectedElemSym: newValue[this.props.focusedMaterialSystem]['selected_elems'] })
        this.props.setProps({value: newValue });
      } else {
        selectedElems = this.props.value[this.props.focusedMaterialSystem]['selected_elems'].concat([elemSym])
        newValue[this.props.focusedMaterialSystem]['selected_elems'] = selectedElems

        this.setState({ selectedElemSym: newValue[this.props.focusedMaterialSystem]['selected_elems'] })
        this.props.setProps({value: newValue});
      }

      this.props.setProps({ lastUpdated: Date.now() });
    }

    singleSelectElem(elemSym) {
        if(this.state.selectedElemSym == elemSym){
          this.resetSelection()
        } else {
          this.setState({ selectedElemSym: [elemSym] });
          var newValue = Object.assign({}, this.props.value)
          newValue[this.props.focusedMaterialSystem]['selected_elems'] = [elemSym]

          this.props.setProps({ lastUpdated: Date.now() });
          this.props.setProps({ value: newValue });
        }
    }

    setSelectedElem(elemSym) {
      if(this.props.avaliableElements.includes(elemSym)){
        if(this.props.mode == "single" || this.props.mode == "ternary" || this.props.mode == "quaternary" || this.props.mode == "quinary") {
          this.singleSelectElem(elemSym)
        } else {
          this.multiSelectElem(elemSym)
        }
      }
    }

    constructor(props) {
      super(props);
    }



    render() {
        const elementData = [
          {"number": 1, "name": "Hydrogen", "symbol": "H", "class": "diatomic"},
          {"number": 2, "name": "Helium", "symbol": "He", "class": "diatomic"},
          {"number": 3, "name": "Lithium", "symbol": "Li", "class": "alkali"},
          {"number": 4, "name": "Beryllium", "symbol": "Be", "class": "alkaline"},
          {"number": 5, "name": "Boron", "symbol": "B", "class": "metalloid"},
          {"number": 6, "name": "Carbon", "symbol": "C", "class": "polyatomic"},
          {"number": 7, "name": "Nitrogen", "symbol": "N", "class": "diatomic"},
          {"number": 8, "name": "Oxygen", "symbol": "O", "class": "diatomic"},
          {"number": 9, "name": "Fluorine", "symbol": "F", "class": "diatomic"},
          {"number": 10, "name": "Neon", "symbol": "Ne", "class": "noble"},
          {"number": 11, "name": "Sodium", "symbol": "Na", "class": "alkali"},
          {"number": 12, "name": "Magnesium", "symbol": "Mg", "class": "alkaline"},
          {"number": 13, "name": "Aluminium", "symbol": "Al", "class": "post-transition"},
          {"number": 14, "name": "Silicon", "symbol": "Si", "class": "metalloid"},
          {"number": 15, "name": "Phosphorus", "symbol": "P", "class": "polyatomic"},
          {"number": 16, "name": "Sulfur", "symbol": "S", "class": "polyatomic"},
          {"number": 17, "name": "Chlorine", "symbol": "Cl", "class": "diatomic"},
          {"number": 18, "name": "Argon", "symbol": "Ar", "class": "noble"},
          {"number": 19, "name": "Potassium", "symbol": "K", "class": "alkali"},
          {"number": 20, "name": "Calcium", "symbol": "Ca", "class": "alkaline"},
          {"number": 21, "name": "Scandium", "symbol": "Sc", "class": "transition"},
          {"number": 22, "name": "Titanium", "symbol": "Ti", "class": "transition"},
          {"number": 23, "name": "Vanadium", "symbol": "V", "class": "transition"},
          {"number": 24, "name": "Chromium", "symbol": "Cr", "class": "transition"},
          {"number": 25, "name": "Manganese", "symbol": "Mn", "class": "transition"},
          {"number": 26, "name": "Iron", "symbol": "Fe", "class": "transition"},
          {"number": 27, "name": "Colbalt", "symbol": "Co", "class": "transition"},
          {"number": 28, "name": "Nickel", "symbol": "Ni", "class": "transition"},
          {"number": 29, "name": "Copper", "symbol": "Cu", "class": "transition"},
          {"number": 30, "name": "Zinc", "symbol": "Zn", "class": "transition"},
          {"number": 31, "name": "Gallium", "symbol": "Ga", "class": "post-transition"},
          {"number": 32, "name": "Germanium", "symbol": "Ge", "class": "metalloid"},
          {"number": 33, "name": "Arsenic", "symbol": "As", "class": "metalloid"},
          {"number": 34, "name": "Selenium", "symbol": "Se", "class": "polyatomic"},
          {"number": 35, "name": "Bromine", "symbol": "Br", "class": "diatomic"},
          {"number": 36, "name": "Krypton", "symbol": "Kr", "class": "noble"},
          {"number": 37, "name": "Rubidium", "symbol": "Rb", "class": "alkali"},
          {"number": 38, "name": "Strontium", "symbol": "Sr", "class": "alkaline"},
          {"number": 39, "name": "Yttrium", "symbol": "Y", "class": "transition"},
          {"number": 40, "name": "Zirconium", "symbol": "Zr", "class": "transition"},
          {"number": 41, "name": "Niobium", "symbol": "Nb", "class": "transition"},
          {"number": 42, "name": "Molybdenum", "symbol": "Mo", "class": "transition"},
          {"number": 43, "name": "Technetium", "symbol": "Tc", "class": "transition"},
          {"number": 44, "name": "Ruthenium", "symbol": "Ru", "class": "transition"},
          {"number": 45, "name": "Rhodium", "symbol": "Rh", "class": "transition"},
          {"number": 46, "name": "Palladium", "symbol": "Pd", "class": "transition"},
          {"number": 47, "name": "Silver", "symbol": "Ag", "class": "transition"},
          {"number": 48, "name": "Cadmium", "symbol": "Cd", "class": "transition"},
          {"number": 49, "name": "Indium", "symbol": "In", "class": "post-transition"},
          {"number": 50, "name": "Tin", "symbol": "Sn", "class": "post-transition"},
          {"number": 51, "name": "Antimony", "symbol": "H", "class": "diatomic"},
          {"number": 52, "name": "Tellurium", "symbol": "Te", "class": "metalloid"},
          {"number": 53, "name": "Iodine", "symbol": "I", "class": "diatomic"},
          {"number": 54, "name": "Xenon", "symbol": "Xe", "class": "noble"},
          {"number": 55, "name": "Cesium", "symbol": "Cs", "class": "alkali"},
          {"number": 56, "name": "Barium", "symbol": "Ba", "class": "alkaline"},
          {"number": 57, "name": "Lanthanum", "symbol": "La", "class": "lanthanide"},
          {"number": 72, "name": "Hafnium", "symbol": "Hf", "class": "transition"},
          {"number": 73, "name": "Tantalum", "symbol": "Ta", "class": "transition"},
          {"number": 74, "name": "Tungsten", "symbol": "W", "class": "transition"},
          {"number": 75, "name": "Rhenium", "symbol": "Re", "class": "transition"},
          {"number": 76, "name": "Osmium", "symbol": "Os", "class": "transition"},
          {"number": 77, "name": "Iridium", "symbol": "Ir", "class": "transition"},
          {"number": 78, "name": "Platinum", "symbol": "Pt", "class": "transition"},
          {"number": 79, "name": "Gold", "symbol": "Au", "class": "transition"},
          {"number": 80, "name": "Mercury", "symbol": "Hg", "class": "transition"},
          {"number": 81, "name": "Thallium", "symbol": "Tl", "class": "post-transition"},
          {"number": 82, "name": "Lead", "symbol": "Pb", "class": "post-transition"},
          {"number": 83, "name": "Bismuth", "symbol": "Bi", "class": "post-transition"},
          {"number": 84, "name": "Polonium", "symbol": "Po", "class": "post-transition"},
          {"number": 85, "name": "Astatine", "symbol": "At", "class": "metalloid"},
          {"number": 86, "name": "Radon", "symbol": "Rn", "class": "noble"},
          {"number": 87, "name": "Francium", "symbol": "Fr", "class": "alkali"},
          {"number": 88, "name": "Radium", "symbol": "Ra", "class": "alkaline"},
          {"number": 89, "name": "Actinium", "symbol": "Ac", "class": "actinide"},
          {"number": 104, "name": "Rutherfordium", "symbol": "Fr", "class": "transition"},
          {"number": 105, "name": "Dubnium", "symbol": "Db", "class": "transition"},
          {"number": 106, "name": "Seaborgium", "symbol": "Sg", "class": "transition"},
          {"number": 107, "name": "Bohrium", "symbol": "Bh", "class": "transition"},
          {"number": 108, "name": "Hassium", "symbol": "Hs", "class": "transition"},
          {"number": 109, "name": "Meitnerium", "symbol": "Mt", "class": "unknown"},
          {"number": 110, "name": "Darmstadtium", "symbol": "Ds", "class": "unknown"},
          {"number": 111, "name": "Roentgenium", "symbol": "Rg", "class": "transition"},
          {"number": 112, "name": "Copernicium", "symbol": "Cn", "class": "transition"},
          {"number": 113, "name": "Nihonium", "symbol": "Nh", "class": "unknown"},
          {"number": 114, "name": "Fleorvium", "symbol": "Fl", "class": "post-transition"},
          {"number": 115, "name": "Moscovium", "symbol": "Mc", "class": "unknown"},
          {"number": 116, "name": "Livermorium", "symbol": "Lv", "class": "unknown"},
          {"number": 117, "name": "Tennessine", "symbol": "Ts", "class": "unknown"},
          {"number": 118, "name": "Oganesson", "symbol": "Og", "class": "unknown"},
          {"number": 119, "name": "Ununennium", "symbol": "Uue", "class": "unknown"},
          {"number": 58, "name": "Cerium", "symbol": "Ce", "class": "lanthanide"},
          {"number": 59, "name": "Praseodymium", "symbol": "Pr", "class": "lanthanide"},
          {"number": 60, "name": "Neodymium", "symbol": "Nd", "class": "lanthanide"},
          {"number": 61, "name": "Promethium", "symbol": "Pm", "class": "lanthanide"},
          {"number": 62, "name": "Samarium", "symbol": "Sm", "class": "lanthanide"},
          {"number": 63, "name": "Europium", "symbol": "Eu", "class": "lanthanide"},
          {"number": 64, "name": "Gadolinium", "symbol": "Gd", "class": "lanthanide"},
          {"number": 65, "name": "Terbium", "symbol": "Tb", "class": "lanthanide"},
          {"number": 66, "name": "Dysprosium", "symbol": "Dy", "class": "lanthanide"},
          {"number": 67, "name": "Holmium", "symbol": "Ho", "class": "lanthanide"},
          {"number": 68, "name": "Erbium", "symbol": "Er", "class": "lanthanide"},
          {"number": 69, "name": "Thulium", "symbol": "Tm", "class": "lanthanide"},
          {"number": 70, "name": "Ytterbium", "symbol": "Yb", "class": "lanthanide"},
          {"number": 71, "name": "Lutetium", "symbol": "Tm", "class": "lanthanide"},
          {"number": 90, "name": "Thorium", "symbol": "Th", "class": "actinide"},
          {"number": 91, "name": "Protactinium", "symbol": "Pa", "class": "actinide"},
          {"number": 92, "name": "Uranium", "symbol": "U", "class": "actinide"},
          {"number": 93, "name": "Neptunium", "symbol": "Np", "class": "actinide"},
          {"number": 94, "name": "Plutonium", "symbol": "Pu", "class": "actinide"},
          {"number": 95, "name": "Americium", "symbol": "Am", "class": "actinide"},
          {"number": 96, "name": "Curium", "symbol": "Cm", "class": "actinide"},
          {"number": 97, "name": "Berkelium", "symbol": "Bk", "class": "actinide"},
          {"number": 98, "name": "Californium", "symbol": "Cf", "class": "actinide"},
          {"number": 99, "name": "Einsteinium", "symbol": "Es", "class": "actinide"},
          {"number": 100, "name": "Fermium", "symbol": "Fm", "class": "actinide"},
          {"number": 101, "name": "Mendelevium", "symbol": "Md", "class": "actinide"},
          {"number": 102, "name": "Nobelium", "symbol": "No", "class": "actinide"},
          {"number": 103, "name": "Lawrencium", "symbol": "Lr", "class": "actinide"}
        ]

        const {id, setProps, value, focusedMaterialSystem, avaliableElements, baseElements, lastUpdated, mode} = this.props;

        return (
            <div className="wrapper">
            <div id="table">

            {elementData.map(element => (
              <Element
                key={`${focusedMaterialSystem}_${element.name}`}
                avaliableElements={avaliableElements}
                baseElements={baseElements}
                selectElem={this.setSelectedElem.bind(this, element.symbol)}
                currentlySelected={value[focusedMaterialSystem]['selected_elems']}
                num={element.number}
                name={element.name}
                symbol={element.symbol}
                elemClass={element.class} />
            ))}
            </div>
          </div>
        );
    }
}

PeriodicTableComponent.defaultProps = {};

PeriodicTableComponent.propTypes = {
    focusedMaterialSystem: PropTypes.string,
    avaliableElements: PropTypes.array,
    baseElements: PropTypes.array,
    id: PropTypes.string,
    value: PropTypes.object,
    mode: PropTypes.string,
    lastUpdated: PropTypes.number,

    /**
     * Dash-assigned callback that should be called to report property changes
     * to Dash, to make them available for callbacks.
     */
    setProps: PropTypes.func
};
