import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class PolymerClassSelectorComponent extends Component {
    state = {
        value: ["Acetal Linkage"],
        selectedPolymerPage: 0
    };

    setPolymerClass(polymerClass) {
        var newSelection = []
        if(this.state.value.includes(polymerClass)){
            newSelection = this.deselectPolymer(polymerClass)
        } else {
            newSelection = this.state.value.concat([polymerClass])
        }
        this.props.setProps({value: newSelection});
        this.props.setProps({ lastUpdated: Date.now() });
        this.setState(prevState => ({ value: newSelection }))
    }

    deselectPolymer(polymerClass) {
        var currentPolymers = this.state.value;
        var index = currentPolymers.indexOf(polymerClass);

        if (index !== -1) {
          currentPolymers.splice(index, 1);
          this.props.setProps({ lastUpdated: Date.now() });
          return currentPolymers;
        }
      }

    createPolymerPages(polymerClass) {
        var pageContent = [];

        while (polymerClass.length > 0 ) {
            const page = polymerClass.splice(0, 30);
            pageContent.push(page);
        }

        return pageContent
    }

    previousButtonState(activePage) {
        if (activePage > 0) {
            return false;
        } else {
            return true;
        }
    }

    nextButtonState(pageCount, activePage) {
        pageCount = pageCount - 1;

        if (activePage >= pageCount) {
            return true;
        } else {
            return false;
        }
    }

    goToPreviousPolymerPage(currentPolymerPage) {
        var newPolymerPage = currentPolymerPage - 1

        this.setState(prevState => ({ selectedPolymerPage: newPolymerPage }))
    }

    goToNextPolymerPage(currentPolymerPage) {
        var newPolymerPage = currentPolymerPage + 1

        this.setState(prevState => ({ selectedPolymerPage: newPolymerPage }))
    }

    get_image_file_name(polymerName) {
        return "./assets/img/" + polymerName.replace(/\s/g, '-') + ".png"
    }

    imageExists(polymerName) {
        const polymersWithImages = ['polyurethane', 'epoxy resin', 'polyester',
            'poly(ester-co-ether)', 'polystyrene', 'polyacrylate', 'silicone',
            'polyvinyl alcohol', 'polycyclooctene', 'polymethacrylate', 'polynorbornene',
            'poly(p-dioxanone)', 'polysulfide', 'natural rubber',
            'styrenic block copolymer', 'polycarbonate', 'poly(ethylene-co-vinyl acetate)', 'bismaleimide resin',
            'Polystyrene', 'seed oil resin', 'styrene butadiene rubber', 'poly(styrene-co-acrylonitrile)',
            'polybutadiene', 'trans-1,4-polyisoprene', 'polyethylene', 'polypropylene',
            'polyacetylene', 'polyolefin', 'polyacrylate copolymer', 'polyoctenamer',
            'polyamide', 'poly(ethylene-co-methacrylic acid)', 'nitrile rubber', 'poly(tetrafluoroethylene)',
            'poly(methylene-1,3-cyclopentane)', 'poly(vinyl ether)',
            'Polyvinyl Alcohol', 'polyvinyl chloride', 'polymethacrylate copolymer',
            'poly(alkyl acrylamide copolymer)', 'poly-3-hydroxyalkanoate copolymer', 'Polyacrylate',
            'poly(alkyl acrylamide)', 'Polyvinyl alcohol', 'cellulose acetate', 'polyimide',
            'poly(acryl amide-co-vinyl imidazole)', 'poly(acrylate-co-acrylamide)',
            'poly(allylamine)', 'poly(ester-co-imide)', 'polyimine', 'cellulose',
            'poly(amide-co-ether)', 'poly(ester-co-amide)', 'chitosan', 'poly(vinylidene fluoride)',
            'poly(vinylidene fluoride-co-hexafluoropropylene)',
            'polysilane', 'poly(vinyl acetate-co-vinyl chloride)', 'polyaryletherketone',
            'polysaccharide', 'protein', 'locust bean gum resin', 'poly(zwitterion)', 'polyelectrolyte complex',
            'polybenzoxazine', 'polythiophene',
            'poly(dicyclopentadiene)', 'polyvinyl pyridine',
            'polyphenylene sulfide', 'polyisocyanurate', 'polyphosphazene', 'cyanate resin',
            'Polyaryletherketone', 'gelatin'
        ]

        return polymersWithImages.includes(polymerName)
    }

    formatPolymerList(polymerList) {
        var newPolymerList = []

        for (let index = 0; index < polymerList.length; index++) {
            newPolymerList.push({"name": polymerList[index], "image": this.get_image_file_name(polymerList[index]), "custom-style": {}})

        }

        return newPolymerList;
    }

    render() {
        const {id, value, polymerList, lastUpdated, setProps} = this.props;

        const polymerClasses = [
            {"name": "Acetal Linkage", "image": "./assets/img/acetal-linkage.png", "custom-style": {}},
            {"name": "Acetate based", "image": "./assets/img/acetate-based.png", "custom-style": {}},
            {"name": "Acryloyl based", "image": "./assets/img/acryloyl-based.png", "custom-style": {}},
            {"name": "Amide based", "image": "./assets/img/amide-based.png", "custom-style": {}},
            {"name": "Amine based", "image": "./assets/img/amine-based.png", "custom-style": {}},
            {"name": "Azide based", "image": "./assets/img/azide-based.png", "custom-style": {}},
            {"name": "Benzoxazine", "image": "./assets/img/benzoxazine.png", "custom-style": {}},
            {"name": "Biobased", "image": "./assets/img/biobased.png", "custom-style": {}},
            {"name": "Bismaleimide", "image": "./assets/img/bismaleimide.png", "custom-style": {}},
            {"name": "Calomer™, produced by The Polymer Technology Group, Inc", "image": "./assets/img/calomer.png", "custom-style": {}},
            {"name": "Polycaprolactone", "image": "./assets/img/Polycaprolactone.png", "custom-style": {}},
            {"name": "Cyanate based", "image": "./assets/img/cyanate-based.png", "custom-style": {}},
            {"name": "Disulfide linkage", "image": "./assets/img/disulfide-linkage.png", "custom-style": {}},
            {"name": "Divinyl benzene", "image": "./assets/img/divinyl-benzene.png", "custom-style": {}},
            {"name": "Elastomers", "image": "./assets/img/elastomers.png", "custom-style": {}},
            {"name": "Epoxy", "image": "./assets/img/epoxy.png", "custom-style": {}},
            {"name": "Fluoropolymers", "image": "./assets/img/fluoropolymers.png", "custom-style":  {}},
            {"name": "Imide based", "image": "./assets/img/imide-based.png", "custom-style": {}},
            {"name": "Maleic acid based", "image": "./assets/img/maleic-acid-based.png", "custom-style": {}},
            {"name": "NOA63-Norland Optical Adhesive 63", "image": "./assets/img/NOA63-Norland-Optical-Adhesive-63.png", "custom-style": {}},
            {"name": "Norbornene", "image": "./assets/img/norbornene.png", "custom-style": {}},
            {"name": "Oxazoline", "image": "./assets/img/oxazoline.png", "custom-style": {}},
            {"name": "Polyamic acid", "image": "./assets/img/polyamic-acid.png", "custom-style": {}},
            {"name": "Polyacrylate", "image": "./assets/img/polyacrylate.png", "custom-style": {}},
            {"name": "Polyacrylic acid", "image": "./assets/img/polyacrylic-acid.png", "custom-style": {}},
            {"name": "Polyacrylonitrile", "image": "./assets/img/Polyacrylonitrile.png", "custom-style": {}},
            {"name": "Polyaryletherketone", "image": "./assets/img/Polyacrylonitrile.png", "custom-style": {}},
            {"name": "Polycarbonate", "image": "./assets/img/Polycarbonate.png", "custom-style": {}},
            {"name": "Polyisocyanurate based", "image": "./assets/img/polyisocyanurate-based.png", "custom-style": {}},
            {"name": "Polyolefins", "image": "./assets/img/Polyolefins.png", "custom-style": {}},
            {"name": "Polyphenylene sulfide", "image": "./assets/img/polyphenylene-sulfide.png", "custom-style": {}},
            {"name": "Polyphosphazene", "image": "./assets/img/polyphosphazene.png", "custom-style": {}},
            {"name": "Polypyrrole", "image": "./assets/img/polypyrrole-sulfide.png", "custom-style": {}},
            {"name": "Polystyrene", "image": "./assets/img/polystyrene.png", "custom-style": {}},
            {"name": "Triazole based", "image": "./assets/img/triazole-based.png", "custom-style": {}},
            {"name": "Polyether", "image": "./assets/img/polyether.png", "custom-style": {}},
            {"name": "Polyvinyl butyral", "image": "./assets/img/polyvinyl-butyral.png", "custom-style": {}},
            {"name": "Terephthalic acid", "image": "./assets/img/terephthalic-acid.png", "custom-style": {}},
            {"name": "Verowhite", "image": "./assets/img/verowhite.png", "custom-style": {}},
            {"name": "Polyurethane", "image": "./assets/img/polyurethane.png", "custom-style": {}},
            {"name": "Thiourea-based", "image": "./assets/img/thiourea-based.png", "custom-style": {}},
            {"name": "Polyvinyl chloride", "image": "./assets/img/polyvinyl-chloride.png", "custom-style": {}},
            {"name": "Thiophene based", "image": "./assets/img/thiophene-based.png", "custom-style": {}},
            {"name": "Polyvinyl alcohol", "image": "./assets/img/polyvinyl-alcohol.png", "custom-style": {}},
            {"name": "Sulfone linkage", "image": "./assets/img/sulfone-linkage.png", "custom-style": {}},
            {"name": "Polyester", "image": "./assets/img/polyester.png", "custom-style": {}},
            {"name": "Polyelectrolyte based", "image": "./assets/img/polyelectrolyte-based.png", "custom-style": {}},
            {"name": "Siloxane based", "image": "./assets/img/siloxane-based.png", "custom-style": {}},
        ]

        var polymers = this.formatPolymerList(polymerList)
        var polymerPages = this.createPolymerPages(polymers)


        return(
            <div className="polymer-class-selection-wrapper">
                {polymerPages.map((page, index) => (
                    <div
                        key={`polymer-page-${index}`}
                        className={`polymer-page ${this.state.selectedPolymerPage ==  index ? 'show-polymer-page' : 'hidden'}`}
                    >
                        {page.map(polymer => (
                            <div key={polymer["name"]}
                                className={`polymer-class ${polymer["name"]} ${value.includes(polymer["name"]) ? 'selected' : ''}`}
                                onClick={this.setPolymerClass.bind(this, polymer["name"])}
                            >
                                <div className="representative-chemical-structure">
                                    {this.imageExists(polymer["name"]) ?
                                        <div className="representative-chemical-structure"><img src={this.get_image_file_name(polymer["name"])}></img></div> :
                                        <span></span>}
                                </div>
                                {this.imageExists(polymer["name"]) ? <hr /> : <span></span> }

                                <div style={polymer["custom-style"]}
                                    className="base-chemical-class">
                                        {polymer["name"]}
                                </div>
                            </div>
                        ))}
                    </div>
                ))}

                <div className='page-buttons'>
                    <button
                        disabled={this.previousButtonState(this.state.selectedPolymerPage)}
                        onClick={this.goToPreviousPolymerPage.bind(this, this.state.selectedPolymerPage)}
                    >Previous</button>
                    <button
                        disabled={this.nextButtonState(polymerPages.length, this.state.selectedPolymerPage)}
                        onClick={this.goToNextPolymerPage.bind(this, this.state.selectedPolymerPage)}
                    >Next</button>
                </div>
            </div>

        );
    }
}

PolymerClassSelectorComponent.defaultProps = {};

PolymerClassSelectorComponent.propTypes = {
    id: PropTypes.string,
    value: PropTypes.array,
    polymerList: PropTypes.array,
    lastUpdated: PropTypes.number,

    /**
     * Dash-assigned callback that should be called to report property changes
     * to Dash, to make them available for callbacks.
     */
    setProps: PropTypes.func
};