import React, {Component} from 'react';
import PropTypes, { symbol } from 'prop-types';

/**
 * ExampleComponent is an example component.
 * It takes a property, `label`, and
 * displays it.
 * It renders an input with the property `value`
 * which is editable by the user.
 */
export default class CustomDataInputComponent extends Component {
    state = {
        columnCount: 3,
        columnHeaders: []
    };

    handleChange = event => {
        this.headerStateUpdate()
      };

    headerStateUpdate() {
        var selectedValues = []
        for (let i = 0; i < this.state.columnCount; i++) {
            let headerID = "header-row-column-" + i + "-dropdown";
            let header = document.getElementById(headerID);
            let headerValue = header.options[header.selectedIndex].text;
            selectedValues.push(headerValue);
        }
        this.setState({columnHeaders: selectedValues});
        this.props.setProps({selectedOptions: selectedValues});
    };        

    plotData() {
        this.updateN_Clicks();
        var inputData = {};
        for (let i = 0; i < this.state.columnCount; i++) {
            let headerID = "header-row-column-" + i + "-dropdown";
            let header = document.getElementById(headerID);
            let headerValue = header.options[header.selectedIndex].text;


            let colID = "col" + i + "";
            let col = document.getElementById(colID);
            let colValue = col.value.toString().replaceAll("\n", ",");
            while(colValue.charAt(colValue.length - 1) == ',') {
                colValue = colValue.slice(0, -1);
            }
            inputData[headerValue] = colValue;
        }        
        this.props.setProps({customDataInput: {inputData}});
        this.props.setProps({n_clicks: this.props.n_clicks + 1});
    }

    updateN_Clicks() {
        this.props.setProps({n_clicks: this.props.n_clicks + 1});
    }
    

    addColumn() { 
        var newColumnCount = null;

        if(this.state.columnCount >= 5) {
            this.setState({columnCount: this.state.columnCount});
        } else {
            this.setState({columnCount: this.state.columnCount + 1});
        }
    }

    removeColumn() {
        var newColumnCount = null

        if(this.state.columnCount <= 2) {
            this.setState({columnCount: this.state.columnCount});
        } else {
            this.setState({columnCount: this.state.columnCount - 1});
        }
    }

    buildColumnInputSection(dropdownOptions) {
        let content = [];
        let fullList = dropdownOptions
        for (let i = 0; i < this.state.columnCount; i++) {
            let colID = "col" + i + "";
            let outerDivID = "outerCol" + i + "";
            let headerID = "header-row-column-" + i + "-dropdown";
            
            content.push(
                <div id={outerDivID}> 
                    <select className="header-dropdown" id={headerID} name= "header-row-column-1" onChange={this.handleChange}>
                        <option value="Hidden Preset" disabled selected hidden>Select Column Header</option>
                        {fullList.map((opt) => this.buildOptionsDropdown(opt, i))}
                    </select>   
                    <textarea className="text-input" id={colID}/>
                </div>
            );
        }
        return content;
    }

    buildOptionsDropdown(opt, id) {
        let selectedOptions = this.props.selectedOptions;
        if(opt == selectedOptions[id]) {
            return <option value={opt} selected>{opt}</option>
        } else if(selectedOptions.includes(opt)) {
            return <option value={opt} disabled>{opt}</option>
        }
        return <option value={opt}>{opt}</option>
    }




    render() {
        const {num, setProps, dropdownOptions, selectedOptions, customDataInput, n_clicks} = this.props;
        


        return (
            <div>
                <div className="input-column-combined">
                    {this.buildColumnInputSection(dropdownOptions)}
                </div>
                <div className="button-row">
                    <button onClick={this.addColumn.bind(this)}>Add Column</button>
                    <button onClick={this.removeColumn.bind(this)}>Remove Column</button>
                    <button className="customDataPlot" id="plotButton" onClick={this.plotData.bind(this)}>Plot</button>
                </div>
            </div>
        );
    }
}

CustomDataInputComponent.defaultProps = {
    n_clicks: 0
};

CustomDataInputComponent.propTypes = {
    id: PropTypes.string,
    dropdownOptions: PropTypes.array,
    selectedOptions: PropTypes.array,
    customDataInput: PropTypes.object,
    n_clicks: PropTypes.number,

    /**
     * Dash-assigned callback that should be called to report property changes
     * to Dash, to make them available for callbacks.
     */
    setProps: PropTypes.func
};
