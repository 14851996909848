import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class MaterialTypeSelectorComponent extends Component {
    state = {
        selected: 'ShapeMemoryAlloys'
      };

    selectMaterial = (materialType) => {
        this.setState({selected: materialType});
        this.props.setProps({value: materialType});
    }

    render() {
        const {id, label, setProps, value} = this.props;

        return (
            <div id="material-type-selector-container" class="horizontal">
              <div
                    onClick={this.selectMaterial.bind(this, "ShapeMemoryAlloys")}
                    className={`material-type ${this.state.selected=='ShapeMemoryAlloys' ? 'selected' : ''}`}>
                    <img src="./assets/img/shape-memory-alloys.png" />
                    <p>Shape Memory Alloys</p>
                </div>

                <div                     
                    onClick={this.selectMaterial.bind(this, "Superelastics")}
                    className={`material-type ${this.state.selected=='Superelastics' ? 'selected' : ''}`}>
                    <img src="./assets/img/superelasticity.png" />
                    <p>Superelastic Alloys</p>
                </div>

                <div                     
                    onClick={this.selectMaterial.bind(this, "MagneticAlloys")}
                    className={`material-type ${this.state.selected=='MagneticAlloys' ? 'selected' : ''}`}>
                    <img src="./assets/img/magnetic-alloys.png" />
                    <p>Magnetic Shape Memory Alloys</p>
                </div>

                <div                     
                    onClick={this.selectMaterial.bind(this, "ShapeMemoryPolymers")}
                    className={`material-type ${this.state.selected=='ShapeMemoryPolymers' ? 'selected' : ''}`}>
                    <img src="./assets/img/shape-memory-polymers.png" />
                    <p>Shape Memory Polymers</p>
                </div>

                <div                     
                    onClick={this.selectMaterial.bind(this, "ShapeMemoryCeramics")}
                    className={`material-type ${this.state.selected=='ShapeMemoryCeramics' ? 'selected' : ''}`}>
                    <img src="./assets/img/shape-memory-ceramics.png" />
                    <p>Shape Memory Ceramics</p>
                </div>

            </div>
        );
    }
}

MaterialTypeSelectorComponent.defaultProps = {};

MaterialTypeSelectorComponent.propTypes = {
    /**
     * The ID used to identify this component in Dash callbacks.
     */
    id: PropTypes.string,

    /**
     * A label that will be printed when this component is rendered.
     */
    label: PropTypes.string,

    /**
     * The value displayed in the input.
     */
    value: PropTypes.string,

    /**
     * Dash-assigned callback that should be called to report property changes
     * to Dash, to make them available for callbacks.
     */
    setProps: PropTypes.func
};
