import React, {Component, Fragment} from 'react';
import PropTypes, { array, symbol } from 'prop-types';
import PeriodicTable from './PeriodicTableComponent.react'

/**
 * ExampleComponent is an example component.
 * It takes a property, `label`, and
 * displays it.
 * It renders an input with the property `value`
 * which is editable by the user.
 */
export default class MultiMaterialSystemSelectorComponent extends Component {
    state = {
        selectedElemSym: [],
        mode: "single"
    }

    setSelectedMaterialSystem(materialSystemName) {
        this.setState({selectedMaterialSystem: materialSystemName});
        this.props.setProps({selectedMaterialSystem: materialSystemName})
    }

    getSelectedElemsForSystem(materialSystemName){
        if(materialSystemName in this.props.value){
            return this.props.value[materialSystemName]
        }else{
            return []
        }
    }

    setSelectionMode(selectionMode) {
        if(this.state.mode === selectionMode){
          this.setState({mode: 'single'});
          this.props.setProps({mode: 'single'});
        } else {
          this.setState({mode: selectionMode});
          this.props.setProps({mode: selectionMode});
        }
      }

    constructor(props) {
        super(props);

        this.state = {
            selectedMaterialSystem: Object.keys(props.materialSystems)[0],
            selectedElems: { currentMaterialSystem: {'selected_elems': []}},
            mode: props.mode
        };

      }

    render() {
        const {id, label, setProps, value, materialSystems, mode, lastUpdated, selectedMaterialSystem} = this.props;

        this.materialSystemNames = Object.keys(materialSystems);
        this.heightPercent = 100 / this.materialSystemNames.length;
        this.systemData = materialSystems[this.props.selectedMaterialSystem]
        this.compoundOptions = this.systemData ? this.systemData['selectable_compound_options'] : []
        this.includesUpperElementalAdditions = this.compoundOptions.length > 0
        console.log(this.includesUpperElementalAdditions)
        this.state.cO = this.compoundOptions
        this.buttonHeightPercent = 100 / this.compoundOptions.length;

        return (
            <div className={`multi-material-sys-selector-container`}>


                <div className="selection-mode-buttons">
                    <button onClick={this.setSelectionMode.bind(this, 'single')}
                                    className={`single-element-button
                                    ${this.state.mode=='single' ? 'selected' : ''}
                                    `}>Single Element</button>

                    <button onClick={this.setSelectionMode.bind(this, 'multi')}
                                className={`multi-element-button
                                ${this.state.mode=='multi' ? 'selected' : ''}
                                `}>Multi Element</button>

                        { this.includesUpperElementalAdditions ?
                            <div className="upper-elemental-addition-section">
                                <h5>Upper Elemental Additions</h5>
                                <div className="upper-elemental-addition-button">
                                    { this.compoundOptions.includes('ternary') ? (
                                        <button  onClick={this.setSelectionMode.bind(this, 'ternary')}
                                            className={`ternary-button
                                            ${this.state.mode=='ternary' ? 'selected' : ''}
                                            `}>Ternary</button>
                                    ): ( null )}

                                    { this.compoundOptions.includes('quaternary') ? (
                                        <button onClick={this.setSelectionMode.bind(this, 'quaternary')}
                                            className={`quaternary-button
                                            ${this.state.mode=='quaternary' ? 'selected' : ''}
                                            `}>Quaternary</button>
                                    ): ( null )}

                                    { this.compoundOptions.includes('quinary') ? (
                                        <button onClick={this.setSelectionMode.bind(this, 'quinary')}
                                            className={`quinary-button
                                            ${this.state.mode=='quinary' ? 'selected' : ''}
                                            `}>Quinary</button>
                                    ): ( null )}
                                </div>
                            </div>
                        : <br></br>
                        }

                </div>

                <div className={`material-system-buttons`}>
                    {this.materialSystemNames.map(materialSystemName => (
                        <div key={`${materialSystemName}-button`}
                            className={`${materialSystemName}-button ${this.props.selectedMaterialSystem == materialSystemName ? 'selected-system' : ''}`}
                        >

                            <button onClick={this.setSelectedMaterialSystem.bind(this, materialSystemName)}>{materialSystemName}</button>
                        </div>
                    ))}
                </div>


                <div class="periodic-table-selector">

                    {this.materialSystemNames.map(materialSystemName =>  (
                        <div
                            key={`${materialSystemName}-periodic-table`}
                            className={this.props.selectedMaterialSystem == materialSystemName ? 'show' : 'hidden'}
                        >
                            <PeriodicTable
                                    setProps={setProps}
                                    id={materialSystemName}
                                    value={value}
                                    selectedElems={this.state.selectedElems}
                                    lastUpdated={lastUpdated}
                                    focusedMaterialSystem={materialSystemName}
                                    baseElements={materialSystems[materialSystemName]['base_elements']}
                                    avaliableElements={materialSystems[materialSystemName]['avaliable_elements']}
                                    mode={this.state.mode}
                            />
                        </div>
                    ))}
                </div>

            </div>
        )
    }
}

MultiMaterialSystemSelectorComponent.defaultProps = {};

MultiMaterialSystemSelectorComponent.propTypes = {
     id: PropTypes.string,
     label: PropTypes.string,
     value: PropTypes.object,
     materialSystems: PropTypes.object,
     mode: PropTypes.string,
     lastUpdated: PropTypes.number,
     selectedMaterialSystem: PropTypes.string,

     /**
      * Dash-assigned callback that should be called to report property changes
      * to Dash, to make them available for callbacks.
      */
     setProps: PropTypes.func
};
