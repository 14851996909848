import React, {Component} from 'react';
import PropTypes, { symbol } from 'prop-types';

/**
 * ExampleComponent is an example component.
 * It takes a property, `label`, and
 * displays it.
 * It renders an input with the property `value`
 * which is editable by the user.
 */
export default class ElementComponent extends Component {
    state = {
        hover: false,
        selected: false
      };
    
    onMouseEnter = (event) => {
        if(this.isAvaliableElement()){
            this.setState({ hover: true });
        }
    };

    onMouseLeave = (event) => {
        if(this.isAvaliableElement()){
            this.setState({ hover: false });
        }
    };

    selectElem = () => {};

    isBaseElement = () => {
        if(this.props.baseElements){
            return this.props.baseElements.includes(this.props.symbol);
        } else {
            return true;
        }
    }

    isAvaliableElement = () => {
        if(this.props.avaliableElements){
            return this.props.avaliableElements.includes(this.props.symbol);
        } else {
            return true;
        }
    }

    render() {
        const {num, name, symbol, elemClass, avaliableElements, baseElements, selectElem, currentlySelected, setProps} = this.props;

        this.isAvaliableElement();
        this.isBaseElement();

        return (
            <div
            title={name}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            onClick={selectElem}
            className={`element element${num} ${elemClass}
                        ${currentlySelected.includes(symbol) ? 'selected' : ''}
                        ${this.state.hover ? 'active' : ''}
                        ${this.isAvaliableElement() ? '' : 'disabled-element' }
                        ${this.isBaseElement() ? 'base-element' : '' }
                        `}>
            <div className="number">{num}</div>
            <div className="symbol">{symbol}</div>
            <div className="element-name">{name}</div>
          </div>
        );
    }
}

ElementComponent.defaultProps = {};

ElementComponent.propTypes = {
    num: PropTypes.string,
    name: PropTypes.string,
    symbol: PropTypes.string,
    elemClass: PropTypes.string,
    avaliableElements: PropTypes.array,
    selectElem: PropTypes.func,
    currentlySelected: PropTypes.array,

    /**
     * Dash-assigned callback that should be called to report property changes
     * to Dash, to make them available for callbacks.
     */
    setProps: PropTypes.func
};
